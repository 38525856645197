// Module
var code = `<template>
	<require from="./blogId.scss"></require>
  <require from="../../../components/nav-bar/nav-bar"></require>
	<require from="../../../components/footer-bottom/footer-bottom"></require>
	<require from="../../../components/max-length"></require>
	<require from="../../../components/ValueConverters/take-value-converter"></require>
	<section id="blogId" class="flex-column">
		<nav-bar class="backdrop-blur"></nav-bar>
    <div class="article_list flex-column">
      <div id="blog_title" class="flex-column">
        <h3 t="blog.subtitle"></h3>
        <h1 t="blog.title"></h1>
      </div>
      <div class="current_article flex-column">
        <div class="img_text flex-row" style="background-image: url(\${article.docs[0].img_url}) ;">
        </div>
        <div class="title_hashtag flex-column">
          <h2 class="title">\${article.docs[0].title}</h2>
          <div class="flex-grow"></div>
          <p class="hashtags">\${article.docs[0].hashtags.join(' ')}</p>
        </div>
        <p class="content" style="white-space: pre-wrap;" innerhtml.bind="mdConverter.makeHtml(article.docs[0].content)"></p>
      </div>
      <h3 class="title" i18n="related_articles"></h3>
      <div class="short_container flex-column">
        <div class="old_container">
          <div class="article_old flex-column" repeat.for="currentArticle of articles.docs"
            click.delegate="goToBlogId(currentArticle.slug)" if.bind="slug != currentArticle.slug">
            <img src.bind="currentArticle.img_url">
            <div class="container">
              <h2 class="title_secondary">\${currentArticle.title | maxLength: 32}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="spacer-default"></div>
    </div>
    <footer-bottom></footer-bottom>
	</section>
</template>
`;
// Exports
export default code;