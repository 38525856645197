import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { ArticleHttpClient } from 'http_clients/ArticleHttpClients';
import { AuthService } from 'aurelia-auth';
import { FileUploadHttpClients } from 'http_clients/FileUploadHttpClients';
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { I18N } from 'aurelia-i18n';
import { ArticleModel } from 'components/models/ArticleModel';
import { ArticlesSearchModel } from 'components/models/ArticlesSearchModel';

@autoinject
export class Blogs {
    
    private articles: Array <ArticlesSearchModel> = [];
    private lastBlog: Array <ArticlesSearchModel> = [];

    constructor(private fileUploadHttpClients: FileUploadHttpClients,
        private articleHttpClients: ArticleHttpClient, private router: Router,
        private authService: AuthService,private i18n: I18N,){

        }

        activate(){
            this.getAllBlogs()
        }

      async  getAllBlogs(){
            let request = await this.articleHttpClients.fetch('/blog/filtered')  
            let is200ok = await checkResponseStatus(request)
            this.articles  = await is200ok.json()
            this.lastBlog  = this.articles

        }

        goToBlogId(currentSlug: string){
            this.router.navigateToRoute('blogsId' , { slug: currentSlug })
        }
    }
