import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { ArticleHttpClient } from 'http_clients/ArticleHttpClients';
import { AuthService } from 'aurelia-auth';
import { FileUploadHttpClients } from 'http_clients/FileUploadHttpClients';
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { I18N } from 'aurelia-i18n';
import { ArticleModel } from 'components/models/ArticleModel';
import { ArticlesSearchModel } from 'components/models/ArticlesSearchModel';
import { Showdown } from 'showdown';


@autoinject
export class blogsId {
    private slug
    private article: Array<ArticlesSearchModel> = [];
    private articles: Array<ArticlesSearchModel> = [];
    private mdConverter;
    private showdown = require('showdown');

    constructor(private fileUploadHttpClients: FileUploadHttpClients,
        private articleHttpClients: ArticleHttpClient, private router: Router,
        private authService: AuthService, private i18n: I18N) {
        this.mdConverter = new this.showdown.Converter();
    }

    activate(params) {
        this.slug = params.slug
        this.getblogBySlug()
        this.getAllBlogs()
    }

    async getblogBySlug() {
        let request = await this.articleHttpClients.fetch('/blog/filtered?slug=' + this.slug)
        let is200ok = await checkResponseStatus(request)
        this.article = await is200ok.json()

    }

    async getAllBlogs() {
        let request = await this.articleHttpClients.fetch('/blog/filtered?limit=5')
        let is200ok = await checkResponseStatus(request)
        this.articles = await is200ok.json()

    }

    goToBlogId(currentSlug: string) {
        this.router.navigateToRoute('blogsId', { slug: currentSlug })
    }
}
