// Module
var code = `<template>
	<require from="./blog.scss"></require>
	<require from="../../components/date-format-short"></require>
  <require from="../../components/nav-bar/nav-bar"></require>
	<require from="../../components/footer-bottom/footer-bottom"></require>
  <require from="../../components/loader-ring/loader-ring"></require>
	<section id="blog" class="column">
    <nav-bar class="backdrop-blur"></nav-bar>
    <div id="blog_title" class="flex-column">
      <h3 t="blog.subtitle"></h3>
      <h1 t="blog.title"></h1>
    </div>
    <loader-ring if.bind="!lastBlog.docs[0]"></loader-ring>
		<div class="article_list flex-column">
			<div class="lastblog flex-column" if.bind="lastBlog.docs[0]" click.delegate="goToBlogId(lastBlog.docs[0].slug)">
				<div class="image">
					<img src.bind="lastBlog.docs[0].img_url">
				</div>
				<div class="container flex-column">
					<div class="title_hashtag flex-column">
						<h2>\${lastBlog.docs[0].title}</h2>
						<div class="flex-grow"></div>
						<p class="hashtag">\${lastBlog.docs[0].hashtags.join(' ')}</p>
					</div>
					<p class="short_content">\${lastBlog.docs[0].short_content}</p>
					<div class="flex-grow"></div>
					<div class="date_more flex-row">
						<p class="date">\${lastBlog.docs[0].createdAt | dateFormatShort}</p>
						<div class="flex-grow"></div>
						<span class="more" t="blog_read_more"></span>
					</div>
				</div>
			</div>
      <h3 t="related_articles" if.bind="(lastBlog.totalDocs > 1)"></h3>
      <div class="related_articles">
        <div class="article flex-column" repeat.for="article of articles.docs"
          click.delegate="goToBlogId(article.slug)" if.bind="(lastBlog.docs[0].slug != article.slug) && (lastBlog.totalDocs > 1)">
          <div class="img_txt">
            <img src.bind="article.img_url || 'img/logo_white.png'">
          </div>
          <div class="container flex-column">
            <div class="title_hashtag flex-column"> 
              <h2>\${article.title}</h2>
              <div class="flex-grow"></div>
              <p class="hashtag">\${article.hashtags.join(' ')}</p>
            </div>
            <p class="short_content">\${article.short_content}</p>
            <div class="flex-grow"></div>
            <div class="date_more flex-row">
              <p class="date">\${article.createdAt | dateFormatShort}</p>
              <div class="flex-grow"></div>
              <span class="more" t="blog_read_more"></span>
            </div>
          </div>
        </div>
      </div>
		</div>
		<div class="spacer-default"></div>
		<footer-bottom></footer-bottom>
	</section>
</template>
`;
// Exports
export default code;